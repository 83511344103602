<template>
    <defaultSec :title="'< 返回'" :returnState="true">
        <el-form :model="eventPorperty" label-width="120px" >
            <el-form-item label="事件id" prop="eventId" v-show="false" >
                <el-input v-model="eventPorperty.eventId" placeholder="事件id"></el-input>
            </el-form-item>
            <el-form-item label="事件名称" prop="eventName" >
                {{eventName}}
            </el-form-item>
            <el-form-item label="事件属性id" prop="eventPropertyId" v-show="false">
                <el-input v-model="eventPorperty.eventPropertyId" placeholder="请输入属性id"></el-input>
            </el-form-item>
            <el-form-item label="请选择属性" prop="propertyId" :required="true">
                <el-select v-model="eventPorperty.propertyId" filterable clearable placeholder="属性">
                    <el-option v-for="item in allPropertyList"
                               :key="item.propertyId"
                               :label="item.propertyName"
                               :value="item.propertyId">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="连接属性" prop="eventPropertyType" :required="true">
                <el-select v-model="eventPorperty.eventPropertyType" filterable clearable placeholder="连接属性">
                    <el-option v-for="item in eventPropertyTypeList"
                               :key="item.value"
                               :label="item.name"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="字典" prop="dicType" :required="true">
                <el-select v-model="eventPorperty.dicType" @change="dicTypeChange" filterable clearable placeholder="字典">
                    <el-option v-for="item in dicTypeList"
                               :key="item.value"
                               :label="item.name"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status" :required="true">
                <el-select v-model="eventPorperty.status" filterable clearable placeholder="状态">
                    <el-option v-for="item in statusList"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="排序" prop="sort" :required="true">
                <el-input-number v-model="eventPorperty.sort" :min="1" :max="1000"
                                 placeholder=""></el-input-number>
            </el-form-item>
            <el-form-item label="备注" prop="remark" :required="true">
                <el-input v-model="eventPorperty.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button-group>
                    <el-button type="warning " size="mini" v-show="eventPorperty.dicType == 1&&eventPorperty.eventPropertyId>0"
                               @click="addDic(eventPorperty.eventPropertyId)">添加字典
                            </el-button>
                    <el-button type="primary" @click="saveEventProperty" size="mini" class="header-btn">
                        保存
                    </el-button>
                </el-button-group>
            </el-form-item>
        </el-form>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import ElDialog from "../../../../node_modules/element-ui/packages/dialog/src/component";
    export default {
        data() {
            return {
                propertyList: [],
                allPropertyList:[],
                eventPropertyTypeList: [],
                statusList: [{id: 0, name: '禁用'}, {id: 1, name: '启用'}],
                showPropertyList: false,
                dataList: [],
                eventName:'',
                dicTypeList: [],
                eventPorperty: {
                    eventPropertyId: this.$route.params.eventPropertyId || 0,
                    eventId: this.$route.params.eventId || 0,
                    propertyId: '',
                    remark: '',
                    dicType: 0,
                    eventType:2,
                    dicDataType: 2,
                    eventPropertyType: 0,
                    status: 1,
                    sort: 1
                },
                total: 0
            }
        },
        components: {
            ElDialog, defaultSec, tablePagination
        },
        mounted(){
            this.initPropertyList()
            this.initDicTypeList()
            this.initPropertyList()
            this.initEventPropertyTypeList()
            this.getEventDetail()
            if (this.$route.params.eventPropertyId > 0) {
                this.getDetail()
                //this.getEventPropertyList()
            }
        },
        methods: {
            initEventPropertyTypeList(){
                this.$apiGet('lechun-cms/scrmproperty/getEventPropertyTypeList', {}).then(res => {
                    console.log(res)
                    this.eventPropertyTypeList = res
                })
            },
            getEventDetail() {
                this.$apiGet('lechun-cms/event/getEvent', {eventId: this.eventPorperty.eventId}).then(res => {
                    this.eventName = res.eventName
                    //this.initPropertyList()
                })
            },
            initDicTypeList(){
                this.$apiGet('lechun-cms/scrmproperty/getDicTypeList', {}).then(res => {
                    console.log(res)
                    this.dicTypeList = res
                })
            },
            initPropertyList() {
                this.allPropertyList = []
                this.$apiGet('lechun-cms/scrmproperty/getPropertyList', {}).then(res => {
                    console.log(res)
                    this.allPropertyList = res
                })
            },
            changeEventType(){
                this.initPropertyList()
            },
            addDic(id){
                this.$router.push({
                    path: '/optionDetail/:id',
                    name: 'optionDetail',
                    params: {id: id }
                });
            },
            dicTypeChange(newValue){
                this.eventPorperty.dicDataType=(this.eventPorperty.dicType==1?this.eventPorperty.dicType:2)
            },
            getDetail() {
                this.$apiGet('lechun-cms/scrmproperty/getEventProperty', {eventPropertyId: this.eventPorperty.eventPropertyId}).then(res => {
                    this.eventPorperty = res
                })
            },
            saveEventProperty(){
                this.$apiGet('lechun-cms/scrmproperty/saveEventProperty', this.eventPorperty).then(res => {
                    console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.$router.push({path: '/eventDetail/:id', name: 'eventDetail', params: {eventId: this.eventPorperty.eventId}})
                })
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>